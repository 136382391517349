import authApi from './auth';
import productApi from './product';
import partnerApi from './partner';
import instituteApi from './institute';
import applicationApi from './application';
import miscApi from './misc';
import leadsApi from './leads';
import usersApi from './users';
import overview from './overview';
import trancheApi from './tranche';
import collectionsApi from './collections';
import cTraceApi from './cTrace';
import userMgmtApi from './userMgmt';
import customersApi from './customers';
import lenstaApi from './lensta';
import purgedDataApi from './configs';

const Api = {
  ...authApi,
  ...productApi,
  ...partnerApi,
  ...instituteApi,
  ...applicationApi,
  ...miscApi,
  ...leadsApi,
  ...usersApi,
  ...overview,
  ...trancheApi,
  ...collectionsApi,
  ...cTraceApi,
  ...userMgmtApi,
  ...customersApi,
  ...lenstaApi,
  ...purgedDataApi
};

export default Api;
