import { hot } from 'react-hot-loader/root';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './App.css';
import { ThemeProvider } from '@material-ui/styles';
import theme from './theme';
import Container from './Container';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Container />
      </Router>
    </ThemeProvider>
  );
}

export default process.env.NODE_ENV === 'development' ? hot(App) : App;
