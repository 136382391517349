import connector from './client';

const getProfileSchema = () => connector.get('/customer/schema');

const getProfile = () => connector.get('/app/profile');

const uploadProfileFile = async (purpose, file, user) => {
  const formData = new FormData();
  formData.append('data', JSON.stringify({ purpose, user }));
  formData.append('files', file);
  const { data } = await connector.post('/customer/upload', formData);
  return data;
};

async function addCustomerUser(payload) {
  const { data } = await connector.post('/customer/addUser', payload);
  return data;
}

const getSignedUrlForProfile = async url => {
  const { data } = await connector.get('/customer/media/sign', {
    params: {
      url,
    }
  });
  return data;
};

const createProfile = async (payload) => {
  const { data } = await connector.post('/customer/profile', payload);
  return data;
};

const customersApi = {
  getProfile,
  getProfileSchema,
  createProfile,
  uploadProfileFile,
  getSignedUrlForProfile,
  addCustomerUser
};

export default customersApi;
