import connector from './client';

async function getOverview() {
  const { data } = await connector.get('/application/get/overview');
  return data;
}

async function getCreditData() {
  const { data } = await connector.get('/application/get/CreditreportsData');
  return data;
}

const overview = {
  getOverview,
  getCreditData
};

export default overview;
