import connector from './client';

async function getAllUserslist(page = null, limit = null, filters = {}) {
  const { data } = await connector.post('/usermgmt/viewUsers', {
    page, limit, filters
  });
  return data;
}

async function addUser(payload) {
  const { data } = await connector.post('/usermgmt/addUser', payload);
  return data;
}

const userMgmtApi = {
  getAllUserslist,
  addUser
};

export default userMgmtApi;
