import connector from './client';

async function getCTraceData(payload) {
  const { data } = await connector.post('/ctrace', payload);
  return data;
}

async function getAllCTraceData(page, size, filters, sorts) {
  const { data } = await connector.post('/ctrace/alldata', {
    page, size, filters, sorts
  });
  return data;
}

async function getCTraceBanks() {
  const { data } = await connector.get('/ctrace/erp/banks');
  return data;
}

async function getCTraceBranches(id) {
  const { data } = await connector.get(`/ctrace/erp/bank_branch?bank=${id}`);
  return data;
}

async function postCTraceBranches(payload) {
  const { data } = await connector.post('/ctrace/branch', payload);
  return data;
}

async function CTraceGetAttributes(id) {
  const { data } = await connector.get(`/ctrace/erp/attributes?id=${id}`);
  return data;
}

async function CTraceupdate(payload) {
  const { data } = await connector.post('/ctrace/update', payload);
  return data;
}

async function CTraceGetCatalog() {
  const { data } = await connector.get('/ctrace/erp/catalogue');
  return data;
}

async function CTraceUploadData(payload) {
  const { data } = await connector.post('/ctrace/erp/upload', payload);
  return data;
}

const cTraceApi = {
  getCTraceData,
  getAllCTraceData,
  getCTraceBanks,
  postCTraceBranches,
  CTraceGetAttributes,
  CTraceupdate,
  CTraceGetCatalog,
  CTraceUploadData,
  getCTraceBranches
};

export default cTraceApi;
