import connector from './client';

async function createPartner(formData) {
  const { data } = await connector.post('/partner', formData);
  return data;
}

async function getPartner(id) {
  const { data } = await connector.get(`/partner/details/${id}`);
  return data;
}
async function getpartnerUser(id) {
  const { data } = await connector.get(`/partner/partnerUser/${id}`);
  return data;
}
async function addPartnerUser(payload, id) {
  const data = await connector.post(`/partner/addPartnerUser/${id}`, payload);
  return data;
}

async function listPartner(page = null, size = null, filters = {}, sorts = []) {
  const { data } = await connector.get('/partner', {
    params: {
      page,
      size,
      filters,
      sorts,
    }
  });
  return data;
}

async function createPartnerScheme(id, req) {
  const { data } = await connector.post(`/partner/${id}/scheme`, req);
  return data;
}

async function getPartnerScheme(id) {
  const { data } = await connector.get(`/partner/scheme/details/${id}`);
  return data;
}

async function listPartnerScheme(id, page, size, filters, sorts) {
  const { data } = await connector.get(`/partner/${id}/scheme`, {
    params: {
      page,
      size,
      filters,
      sorts,
    }
  });
  return data;
}

async function listPartnersByScheme(id) {
  const { data } = await connector.get(`/partner/scheme/${id}`);
  return data;
}

async function updatePartner(formData, id) {
  const { data } = await connector.patch(`/partner/${id}/updatePatrnerDetails`, formData);
  return data;
}

async function uploadPincodes(formData) {
  const { data } = await connector.post('/partner/addPincodes', formData);
  return data;
}

async function changeVersionStatePartner(payload) {
  const { data } = await connector.post('/partner/partnerSchemeUpdate', payload);
  return data;
}

async function addNewVersion(payload) {
  const { data } = await connector.post('/partner/addSchemes', payload);
  return data;
}

async function addPartnerCharges(payload, id) {
  const { data } = await connector.post(`/partner/${id}/partnerCharges`, payload);
  return data;
}

async function getPartnerCharges(id) {
  const { data } = await connector.get(`/partner/${id}/partnerCharges`);
  return data;
}

const partnerApi = {
  createPartner,
  listPartner,
  listPartnerScheme,
  createPartnerScheme,
  listPartnersByScheme,
  getPartner,
  getpartnerUser,
  getPartnerScheme,
  updatePartner,
  uploadPincodes,
  changeVersionStatePartner,
  addNewVersion,
  addPartnerCharges,
  getPartnerCharges,
  addPartnerUser
};

export default partnerApi;
