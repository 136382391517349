import connector from './client';

async function getProductSchema() {
  const { data } = await connector.get('/product/schema');
  return data;
}

async function getProductSchemesSchema() {
  const { data } = await connector.get('/product/schemes/schema');
  return data;
}

async function createProduct(request) {
  const { data } = await connector.post('/product', request);
  return data;
}

async function getProduct(id) {
  const { data } = await connector.get(`/product/details/${id}`);
  return data;
}

async function listProduct(page, size, filters, sorts) {
  const { data } = await connector.get('/product', {
    params: {
      page,
      size,
      filters,
      sorts,
    }
  });
  return data;
}

const productApi = {
  getProductSchema,
  createProduct,
  getProductSchemesSchema,
  listProduct,
  getProduct,
};

export default productApi;
