import connector from './client';

async function getCollections(page, size, filters, sorts) {
  const { data } = await connector.get('/collection/collectionsList', {
    params: {
      page,
      size,
      filters,
      sorts,
    },
  });
  return data;
}

async function getCollectionLogs(collectionId) {
  const { data } = await connector.get(
    `/collection/collections/${collectionId}`
  );
  return data;
}

async function updateCollectionStatus(id, status) {
  const { data } = await connector.post('collection/collections/statusUpdate', {
    id,
    status,
  });
  return data;
}

async function exportCollections(page, size, filters, sorts) {
  const { data } = await connector.get('/collection/get/ExcelReport', {
    params: {
      page,
      size,
      filters,
      sorts,
    },
  });
  return data;
}

const collectionsApi = {
  getCollections,
  getCollectionLogs,
  updateCollectionStatus,
  exportCollections,
};

export default collectionsApi;
