import connector from './client';

async function createInstitute(formData) {
  const { data } = await connector.post('/institute', formData);
  return data;
}

async function getInstituteSchema() {
  const { data } = await connector.get('/institute/schema');
  return data;
}

async function listInstitute(page, size, filters, sorts) {
  const { data } = await connector.get('/institute', {
    params: {
      page,
      size,
      filters,
      sorts,
    },
  });
  return data;
}

const listInstitutes = async (productId) => {
  const { data } = await connector.get(`/application/${productId}/institutes`);
  return data;
};

const listInstituteByProduct = async (productId) => {
  const { data } = await connector.get(`/institute/${productId}/list`);
  return data;
};

const listInstituteSchemes = async (schemeId, productId, instituteId, amount, applicationId) => {
  const { data } = await connector.get(`/application/${productId}/institutes/${instituteId}/scheme/${schemeId}`, {
    params: { amount, applicationId }
  });
  return data;
};

async function listInstituteScheme(id, page, size, filters, sorts) {
  const { data } = await connector.get(`/institute/${id}/scheme`, {
    params: {
      page,
      size,
      filters,
      sorts,
    },
  });
  return data;
}

async function addInstituteScheme(id, schemeId, partners, configs) {
  const { data } = await connector.post(`/institute/${id}/scheme`, {
    schemeId,
    partners,
    configs,
  });
  return data;
}

async function getInstituteDetails(id) {
  const { data } = await connector.get(`/institute/details/${id}`);
  return data;
}

async function getInstituteScheme(id) {
  const { data } = await connector.get(`/institute/schemeDetails/${id}`);
  return data;
}

async function updateInstitute(formData, id) {
  const { data } = await connector.patch(
    `/institute/${id}/updateDetails`,
    formData
  );
  return data;
}

async function uploadStudent(formData) {
  const { data } = await connector.post('/institute/uploadStudent', formData);
  return data;
}

async function changeVersionStateInstitute(payload) {
  const { data } = await connector.post(
    '/institute/instituteSchemeUpdate',
    payload
  );
  return data;
}

async function verifyBankDetails(payload) {
  const { data } = await connector.post(
    '/institute/bankDetailsValidation',
    payload
  );
  return data;
}

async function createBeneficiary(payload) {
  const { data } = await connector.post(
    '/institute/beneficiaryidcreate',
    payload
  );
  return data;
}

async function getInstitute() {
  const { data } = await connector.get(
    '/institute/detail',
  );
  return data;
}

const instituteApi = {
  createInstitute,
  getInstituteSchema,
  listInstitute,
  addInstituteScheme,
  listInstituteScheme,
  getInstituteDetails,
  getInstituteScheme,
  updateInstitute,
  uploadStudent,
  changeVersionStateInstitute,
  verifyBankDetails,
  createBeneficiary,
  listInstitutes,
  listInstituteSchemes,
  getInstitute,
  listInstituteByProduct
};

export default instituteApi;
