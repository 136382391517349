import { defaultConnector as connector } from './client';

async function login(token) {
  const { data } = await connector.post('/auth/erp', {
    token
  });
  return data;
}

async function sendPartnerOtp(phone, isPartner) {
  const { data } = await connector.post('/auth/partner/otp', {
    phone,
    isPartner
  });
  return data;
}

async function verifyPartnerOtp(phone, otp, isPartner) {
  const { data } = await connector.post('/auth/partner/verify', {
    phone,
    otp,
    isPartner,
  });
  return data;
}

const authApi = {
  login,
  sendPartnerOtp,
  verifyPartnerOtp,
};

export default authApi;
