import { createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#ee2f58'
    },
    secondary: {
      main: '#343a40'
    },
    green: {
      main: '#4C8400',
      text: '#E1C8CF ',
      light: '#E1C8CF',
      title: '#ECF0F1',
    },
    red: {
      main: '#EE2F58',
      text: '#FFABBF',
      light: '#FFABBF',
      title: '#ECF0F1',
    },
    blue: {
      main: '#9BDEAC',
      text: '#006469',
      light: '#006469',
      title: '#006469',
    }
  },
  typography: {
    fontFamily: 'Open Sans',
    fontSize: 12,
  },
  overrides: {
    MuiCardHeader: {
      root: {
        background: '#f5f5f5',
        paddingLeft: 0,
        paddingTop: 0
      },
      title: {
        fontWeight: 600,
        fontSize: '16px'
      }
    },
    MuiCardContent: {
      root: {
        borderRadius: '6px',
        background: '#ffffff'
      },
    },
    MuiCard: {
      root: {
        boxShadow: 'none',
        overflow: 'none',
      }
    },
    MuiPaper: {
      root: {
        backgroundColor: '#f5f5f5',

      },
      elevation1: {
        boxShadow: 'none',
      },
    },
    MuiCardActions: {
      root: {
        justifyContent: 'flex-end',
      }
    },
    MuiDialogContent: {
      root: {
        fontSize: '14px'
      }
    },
  }
});

export default theme;
