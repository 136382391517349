import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './snackbar.module.css';

const defaultClass = `${styles.snackbar} ${styles.snackbarError}`;

const ErrorSnackbar = ({ visible = true, text }) => (
  <div
    className={classNames(defaultClass, (visible ? styles.snackbarVisible : styles.snackbarHidden))}
  >
    {text}
  </div>
);

ErrorSnackbar.propTypes = {
  visible: PropTypes.bool,
  text: PropTypes.string
};

ErrorSnackbar.defaultProps = {
  visible: false,
  text: ''
};

export default ErrorSnackbar;
