import connector from './client';

async function listLeads(page, size, filters, sorts) {
  const { data } = await connector.get('/leads/user', {
    params: {
      page,
      size,
      filters,
      sorts,
    }
  });
  return data;
}
async function getStudentLeads(page, size, filters, sorts) {
  const { data } = await connector.get('/leads/getStudentLeads', {
    params: {
      page,
      size,
      filters,
      sorts,
    }
  });
  return data;
}

async function listNewLeads(page, size, filters, sorts) {
  const data = await connector.post('/leads/listUser', {
    page, size, filters, sorts
  });
  return data;
}

async function getParams(id) {
  const params = await connector.get(`leads/listParam?id=${id}`);
  return params;
}

async function updateLeadUserStatus(payload) {
  const { data } = await connector.post('/leads/updateLeadActivity', payload);
  return data;
}
async function createLead(payload) {
  const { data } = await connector.post('/leads/cl', payload);
  return data;
}

async function getLeadActivity(id) {
  const { data } = await connector.get(`leads/${id}/history`);
  return data;
}

async function updateLead(payload, id) {
  await connector.post(`/leads/ul?id=${id}`, payload);
}

async function getLeadUserHistory(id) {
  const { data } = await connector.get(`/leads/${id}/getLeadActivity`);
  return data;
}

async function uploadLeadData(payload) {
  const { data } = await connector.post('/leads/uploadLeadData', payload);
  return data;
}
async function partnerList(id) {
  const { data } = await connector.get(`/leads/${id}/partnerList`);
  return data;
}
async function approvePartner(payload) {
  await connector.post('/leads/approvePartner', payload);

}
async function getAdminUser() {
  const { data } = await connector.get('/leads/getuser');
  return data;
}

const leadsApi = {
  listLeads,
  updateLeadUserStatus,
  getLeadUserHistory,
  createLead,
  updateLead,
  listNewLeads,
  getParams,
  getLeadActivity,
  uploadLeadData,
  partnerList,
  approvePartner,
  getAdminUser,
  getStudentLeads
};

export default leadsApi;
