import connector from './client';

async function getPendingPayment() {
  const { data } = await connector.get('/tranche/getPendingTranches');
  return data;
}

async function saveConfig(partnerSchemeVersionId, config) {
  const { data } = await connector.post('/tranche/saveTranceConfig', {
    partnerSchemeVersionId,
    config,
  });
  return data;
}

async function getTrancheConfig(id) {
  const { data } = await connector.get(`/tranche/${id}/getConfig`);
  if (data[0]?.config) {
    const currdata = JSON.parse(data[0]?.config);
    // eslint-disable-next-line array-callback-return
    currdata.map(el => {
      // eslint-disable-next-line no-prototype-builtins
      if (!el.hasOwnProperty('advanceEMI')) {
        el.advanceEMI = 0;
      }
    });
    return currdata;
  }
  return null;
}

const trancheApi = {
  getPendingPayment,
  saveConfig,
  getTrancheConfig,
};

export default trancheApi;
