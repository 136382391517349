import connector from './client';
import { download } from './utils';

async function listApplications(page, size, filters, sorts) {
  const { data } = await connector.get('/application', {
    params: {
      page,
      size,
      filters,
      sorts,
    }
  });
  return data;
}

async function getApplication(id) {
  const { data } = await connector.get(`/application/${id}`);
  return data;
}
async function initiate(productId) {
  const { data } = await connector.get(`personal/initiate/${productId}`);
  return data;
}

async function setApplicationParameterStatus(applicationId, parameterId, status, note) {
  const { data } = await connector.patch(
    `/application/${applicationId}/${parameterId}/status`, {
      status,
      note
    }
  );
  return data;
}

async function setApplicationStatus(applicationId, status, reason, remarks) {
  const { data } = await connector.patch(
    `/application/${applicationId}/status`, {
      status,
      remarks,
      reason
    }
  );
  return data;
}

async function disburseApplication(applicationId, amount, trancheId) {
  const { data } = await connector.post(
    `/application/${applicationId}/disburse`, {
      amount: +amount,
      trancheId: +trancheId
    }
  );
  return data;
}

async function getDisburseApplicationView(applicationId) {
  const { data } = await connector.get(`/application/${applicationId}/disburse`);
  return data;
}

async function getApplicationParameterStatusHistory(applicationId, parameterId) {
  const { data } = await connector.get(
    `/application/${applicationId}/${parameterId}/status/history`,
  );
  return data;
}

async function getMandateLogs(applicationId) {
  const { data } = await connector.get(
    `/digio/getEnachLogs/${applicationId}`,
  );
  return data;
}

async function getApplicationStatusHistory(applicationId, isAssigned) {
  const { data } = await connector.get(
    `/application/${applicationId}/status/history`, {
      params: {
        isAssigned
      }
    }
  );
  return data;
}

async function getDisbursementHistory(applicationId) {
  const { data } = await connector.get(`/application/getPaymentHistory/${applicationId}`);
  return data;
}

async function getApplicationStatuses() {
  const { data } = await connector.get('/application/status/list');
  return data;
}

async function callUser(applicationId, callee) {
  const data = await connector.post(
    `/application/${applicationId}/callUser`, {
      callee,
    }
  );
  return data;
}

async function exportApplication(applicationId, complete = false) {
  await download(connector.get(`/application/${applicationId}/export`, {
    params: complete ? { complete } : {},
    responseType: 'blob'
  }));
}

async function updateTag(applicationId, params) {
  const { data } = await connector.patch(`/application/${applicationId}/tags`, params);
  return data;
}

async function setAssignee(applicationId, params) {
  const { data } = await connector.patch(`/application/${applicationId}/assign`, params);
  return data;
}

async function exportApplications(page, size, filters, sorts) {
  const { data } = await connector.get('/application/get/ExcelReport', {
    params: {
      page,
      size,
      filters,
      sorts,
    }
  });
  return data;
}

async function getRemarks(applicationId) {
  const { data } = await connector.get(`/application/getRemarks/${applicationId}`);
  return data;
}

async function uploadAdminFiles(formData) {
  const { data } = await connector.post('/application/uploadAdminFiles', formData);
  return data;
}
async function updateFee(applicationId, updatedFee, note) {
  const { data } = await connector.patch(
    `/application/updateFee/${applicationId}`, {
      updatedFee,
      note
    }
  );
  return data;
}

async function getFeeHistory(applicationId) {
  const { data } = await connector.get(`/application/getFeeEditHistory/${applicationId}`);
  return data;
}

async function setAddress(applicationId, params) {
  const { data } = await connector.patch(`/application/edit/${applicationId}/Address`, params);
  return data;
}

async function enableCoApplicantFlow(applicationId) {
  const { data } = await connector.post(`/application/enableCoApplicantFlow/${applicationId}`);
  return data;
}

async function getBankAnalysisData(payload) {
  const { data } = await connector.post('/pichainlabs/bankStatementAnalysis', payload);
  return data;
}

async function updatePartnerForApplication(payload) {
  const { data } = await connector.post('/application/updatePartner', payload);
  return data;
}

async function createApplication(payload) {
  const { data } = await connector.post('/application/create', payload);
  return data;
}
const createPersonal = async (productId, values) => {
  const { data } = await connector.post(`/personal/initiate/${productId}`, values);
  return data;
};

const uploadApplicationFile = async (id, files) => {
  const formData = new FormData();
  files.forEach(file => {
    formData.append('files', file);
  });
  const { data } = await connector.post(`/application/${id}/upload`, formData);
  return data;
};

const getSignedUrlForApplication = async (id, url) => {
  const { data } = await connector.get(`/application/${id}/media/sign`, {
    params: {
      url,
    }
  });
  return data;
};

const patchApplication = async (id, values) => {
  const { data } = await connector.patch(`/application/${id}`, values);
  return data;
};

const submitApplication = async (id, autoSubmit = false) => {
  const { data } = await connector.post(`/application/${id}/submit`, {
    params: {
      autoSubmit,
    }
  });
  return data;
};

// application tabs
const getUserProfile = async (id) => {
  const { data } = await connector.get(`/application/${id}/getUserProfile`);
  return data;
};

const getDisbursementInfo = async (id) => {
  const { data } = await connector.get(`/application/${id}/getDisbursementInfo`);
  return data;
};

const getEsignENachDetails = async (id) => {
  const { data } = await connector.get(`/application/${id}/getEsignENachDetails`);
  return data;
};

const getProductSchemesDetails = async (id) => {
  const { data } = await connector.get(`/application/${id}/getProductSchemesDetails`);
  return data;
};

const getAppCreditReport = async (id) => {
  const { data } = await connector.get(`/application/${id}/creditReport`);
  return data;
};

const getAppDocuments = async (id) => {
  const { data } = await connector.get(`/application/${id}/documents`);
  return data;
};

const getAppTrancheDetails = async (id) => {
  const { data } = await connector.get(`/application/${id}/trancheDetails`);
  return data;
};

const getAppRuleEngine = async (id) => {
  const { data } = await connector.get(`/application/${id}/ruleEngine`);
  return data;
};

const bankAnalysisExcel = async (payload, appId) => {
  const { data } = await connector.post('/pichainlabs/bankAnalysisExcel', { payload, appId });
  return data;
};

const getEmiSchedule = async (id) => {
  const { data } = await connector.get(`/application/${id}/getEmiSchedule`);
  return data;
};

const updateUnmaskLog = async (payload) => {
  const data = await connector.post(`/application/${payload.entityId}/attributeUnmaskLog`, { payload });
  return data;
};
const sendLeadToPartner = async (payload) => {
  const data = await connector.post('/application/sendLeadToPartner', payload);
  return data;
};
const getProductPartner = async (id) => {
  const { data } = await connector.get(`/application/${id}/productPartnerMap`);
  return data;
};
const listEmail = async (id) => {
  const { data } = await connector.get(`/application/${id}/listEmail`);
  return data;
};
const mapSchemes = async (id, payload) => {
  const { data } = await connector.post(`/application/${id}/mapSchemes`, payload);
  return data;
};

const applicationApi = {
  listApplications,
  getApplication,
  setApplicationParameterStatus,
  getApplicationParameterStatusHistory,
  getApplicationStatusHistory,
  setApplicationStatus,
  disburseApplication,
  getDisburseApplicationView,
  getApplicationStatuses,
  callUser,
  exportApplication,
  getMandateLogs,
  updateTag,
  setAssignee,
  exportApplications,
  getDisbursementHistory,
  getRemarks,
  uploadAdminFiles,
  updateFee,
  getFeeHistory,
  setAddress,
  enableCoApplicantFlow,
  getBankAnalysisData,
  updatePartnerForApplication,
  createApplication,
  uploadApplicationFile,
  getSignedUrlForApplication,
  patchApplication,
  submitApplication,
  getUserProfile,
  getDisbursementInfo,
  getEsignENachDetails,
  getProductSchemesDetails,
  getAppCreditReport,
  getAppDocuments,
  getAppTrancheDetails,
  getAppRuleEngine,
  bankAnalysisExcel,
  getEmiSchedule,
  updateUnmaskLog,
  getProductPartner,
  sendLeadToPartner,
  listEmail,
  mapSchemes,
  initiate,
  createPersonal
};

export default applicationApi;
