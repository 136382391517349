import connector from './client';

async function viewLenstaLeads(page, size, filters, sorts) {
  const { data } = await connector.post('/lensta/viewLeads', {
    page, size, filters, sorts
  });
  return data;
}

const lenstaApi = {
  viewLenstaLeads
};

export default lenstaApi;
