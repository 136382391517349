import connector from './client';

const getSignedUrl = async (url) => {
  const { data } = await connector.get('/misc/signed-url', {
    params: {
      url
    }
  });
  return data;
};

const uploadFile = async (file, purpose, access) => {
  const formData = new FormData();
  const dataStr = JSON.stringify({ purpose, access });
  formData.append('files', file);
  formData.append('data', dataStr);
  const { data } = await connector.post('/misc/upload-file', formData);
  return data;
};

const getUser = async () => {
  const { data } = await connector.get('/auth/erp/user');
  return data;
};

const getDocument = async (id) => {
  const { data } = await connector.get(`/digio/getSignedUrl/${id}`);
  return data;
};

const miscApi = {
  getSignedUrl,
  uploadFile,
  getUser,
  getDocument,
};

export default miscApi;
