/* eslint-disable */
import connector from './client';

async function listUsers(page, size, filters, sorts) {
  const { data } = await connector.get('/user/getUserList', {
    params: {
      page,
      size,
      filters,
      sorts,
    }
  });
  return data;
}

async function updateProfileRemarks(payload) {
  const { data } = await connector.put('/user/status', payload);
  return data;
}

async function getProfileStatusHistory(id) {
  const { data } = await connector.get(
    `/user/${id}/status/history`,
  );
  return data;
}

const usersApi = {
  listUsers,
  updateProfileRemarks,
  getProfileStatusHistory,
};

export default usersApi;
