import React, { Suspense, useState, useEffect } from 'react';
import Storage from 'lib/storage';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { UserContext } from 'contexts';
import { isEmpty } from 'lodash';
import Api from 'api';
import withSnackbar from 'hoc/withSnackbar';
import PropTypes from 'prop-types';
import { Login, Home } from './containers';
import Splash from './containers/splash/Splash';

function getRoleMap(user) {
  if (isEmpty(user)) {
    return null;
  }
  const { role } = user;
  switch (role) {
    case 'admin':
      return {
        isAdmin: true, isManager: false, isUser: false, isSales: false
      };
    case 'manager':
      return {
        isAdmin: false, isManager: true, isUser: false, isSales: false
      };
    case 'sales':
      return {
        isAdmin: false, isManager: false, isUser: false, isSales: true
      };
    case 'user':
    default:
      return {
        isAdmin: false, isManager: false, isUser: true, isSales: false
      };
  }
}

function Container(props) {
  const [loggedIn, setLogin] = useState(Storage.isLoggedIn());
  const [user, setUser] = useState(null);
  const { handleError, handleSuccess } = props;
  const roleMap = getRoleMap(user);
  const context = {
    ...roleMap, handleError, handleSuccess, user
  };

  useEffect(() => {
    if (loggedIn) {
      Api.getUser().then(res => setUser(res)).catch(() => setLogin(false));
    }
  }, [loggedIn]);
  return (
    <Router>
      <Suspense fallback={<Splash />}>
        {loggedIn ? (
          <UserContext.Provider value={context}>
            <Home onLogout={() => setLogin(false)} />
          </UserContext.Provider>
        ) : (
          <Route path="*">
            <Login onLogin={() => setLogin(true)} />
          </Route>
        )}
      </Suspense>
    </Router>
  );
}

Container.propTypes = {
  handleError: PropTypes.func.isRequired,
  handleSuccess: PropTypes.func.isRequired,
};

export default withSnackbar(Container);
