import React from 'react';
import logo from 'assets/logo-white.svg';
import { CircularProgress } from '@material-ui/core';
import styles from './Splash.module.css';

function Splash() {
  return (
    <div className={styles.root}>
      <img className={styles.logo} src={logo} alt="" />
      <CircularProgress className={styles.loader} />
    </div>
  );
}

export default Splash;
