import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './snackbar.module.css';

const defaultClass = `${styles.snackbar} ${styles.snackbarSuccess}`;

const SuccessSnackbar = ({ visible = false, text }) => (
  <div className={classNames(defaultClass, (visible ? styles.snackbarVisible : styles.snackbarHidden))}>{text}</div>
);

SuccessSnackbar.propTypes = {
  visible: PropTypes.bool,
  text: PropTypes.string
};

SuccessSnackbar.defaultProps = {
  visible: false,
  text: ''
};

export default SuccessSnackbar;
