import connector from './client';

async function getPurgedData() {
  const data = await connector.get('/application/purgedData');
  return data;
}

const purgedDataApi = {
  getPurgedData
};

export default purgedDataApi;
