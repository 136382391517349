/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import ErrorSnackbar from 'components/shared/Snackbar/error';
import SuccessSnackbar from 'components/shared/Snackbar/success';

const withSnackbar = WrappedComponent => {
  class SnackbarHOC extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        timeoutId: null,
        error: null,
        success: null
      };
    }

    componentWillUnmount() {
      const { timeoutId } = this.state;
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    }

    handleError = error => {
      const { timeoutId } = this.state;
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      this.setState({
        timeoutId: setTimeout(() => {
          this.setState({
            error: null,
            timeoutId: null
          });
        }, 4000),
        error: error.message
      });
    }

    handleSuccess = success => {
      const { timeoutId } = this.state;
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      this.setState({
        timeoutId: setTimeout(() => {
          this.setState({
            success: null,
            timeoutId: null
          });
        }, 4000),
        success: success.message
      });
    }

    render() {
      const { error, success } = this.state;
      return (
        <>
          <ErrorSnackbar visible={error !== null} text={error} />
          <SuccessSnackbar visible={success !== null} text={success} />
          <WrappedComponent handleError={this.handleError} handleSuccess={this.handleSuccess} {...this.props} />
        </>
      );
    }
  }

  if (WrappedComponent.getInitialProps) {
    SnackbarHOC.getInitialProps = WrappedComponent.getInitialProps;
  }

  return SnackbarHOC;
};

export default withSnackbar;
